"use strict";
/*!
 *
 *  menu.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$menu = $('#MenuBtn');
        this.$search = $('#MenuSearch');
        this.$close = $('#MenuClose');
        this.$body = $('#MenuBody');
        this.$bg = $('#MenuBg');
        this.$keywords = $('#SearchInput');
        this.$gridContents = $('#MenuGrid');
        this.$searchContents = $('#Search');
        this.$searchBody = $('#SearchPopularBody');
        var src = util.viewport !== 'phone' ? this.$bg.data('src') : this.$bg.data('src-sm');
        this.$bg.attr('src', src);
        this.setItem();
        this.$menu.on('click', function (e) {
            e.preventDefault();
            if (_this.$body.is('[aria-hidden="true"]')) {
                _this.$gridContents.attr('aria-hidden', 'false');
                if (util.viewport === 'phone') {
                    _this.$searchContents.attr('aria-hidden', 'false');
                }
                ;
                _this.open();
            }
            else {
                _this.close();
            }
            ;
        });
        this.$search.on('click', function (e) {
            if (_this.$body.is('[aria-hidden="true"]')) {
                _this.$searchContents.attr('aria-hidden', 'false');
                _this.open();
            }
            else {
                _this.close();
            }
            ;
        });
        this.$close.on('click', function (e) {
            e.preventDefault();
            _this.close();
        });
    }
    default_1.prototype.open = function () {
        this.$body.attr('aria-hidden', 'false');
    };
    default_1.prototype.close = function () {
        this.$body.attr('aria-hidden', 'true');
        this.$gridContents.attr('aria-hidden', 'true');
        this.$searchContents.attr('aria-hidden', 'true');
    };
    default_1.prototype.setItem = function () {
        var _this = this;
        var keyword = searchPopulars;
        keyword.forEach(function (val) {
            _this.$searchBody.append('<button class="st-Search_Popular_Btn">' + val + '</button>');
        });
        this.$popular = this.$searchBody.find('.st-Search_Popular_Btn');
        this.$popular.on('click', function (e) {
            e.preventDefault();
            _this.setPopular($(e.target).text());
        });
    };
    default_1.prototype.setPopular = function (val) {
        var keywords = this.$keywords.val().toString().split(/[ 　]/);
        keywords.push(val);
        keywords = keywords.filter(function (x, i, self) {
            return self.indexOf(x) === i;
        });
        this.$keywords.val(keywords.join(' '));
    };
    return default_1;
}());
exports.default = default_1;
;
