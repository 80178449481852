"use strict";
/*!
 *
 *  fancybox-defaults-setting.js
 *
 */
$.fancybox.defaults.animationDuration = 700;
$.fancybox.defaults.transitionDuration = 700;
$.fancybox.defaults.arrows = true;
$.fancybox.defaults.infobar = false;
$.fancybox.defaults.toolbar = true;
$.fancybox.defaults.buttons = ['close'];
$.fancybox.defaults.smallBtn = false;
$.fancybox.defaults.wheel = false;
$.fancybox.defaults.transitionEffect = "slide";
$.fancybox.defaults.idleTime = 999999;
$.fancybox.defaults.spinnerTpl = "\n\t<div class=\"sw-Loading\">\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t\t<div class=\"sw-Loading_Item\"></div>\n\t</div>\n";
$.fancybox.defaults.baseTpl =
    '<div class="fancybox-container" role="dialog" tabindex="-1">' +
        '<div class="fancybox-bg"></div>' +
        '<div class="fancybox-inner">' +
        '<div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div>' +
        '<div class="fancybox-toolbar">{{buttons}}</div>' +
        '<div class="fancybox-navigation">{{arrows}}</div>' +
        '<div class="fancybox-stage"></div>' +
        '<div class="fancybox-caption"><div class="fancybox-caption__body"></div></div>' +
        '</div>' +
        '</div>';
$.fancybox.defaults.btnTpl = {
    download: '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;">' +
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.62 17.09V19H5.38v-1.91zm-2.97-6.96L17 11.45l-5 4.87-5-4.87 1.36-1.32 2.68 2.64V5h1.92v7.77z"/></svg>' +
        "</a>",
    zoom: '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}">' +
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.7 17.3l-3-3a5.9 5.9 0 0 0-.6-7.6 5.9 5.9 0 0 0-8.4 0 5.9 5.9 0 0 0 0 8.4 5.9 5.9 0 0 0 7.7.7l3 3a1 1 0 0 0 1.3 0c.4-.5.4-1 0-1.5zM8.1 13.8a4 4 0 0 1 0-5.7 4 4 0 0 1 5.7 0 4 4 0 0 1 0 5.7 4 4 0 0 1-5.7 0z"/></svg>' +
        "</button>",
    arrowLeft: '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}"></button>',
    arrowRight: '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}"></button>',
    smallBtn: '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">Close</button>',
    close: '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}">Close</button>'
};
