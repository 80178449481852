"use strict";
/*!
 *
 * swipe-elem.js
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var SwipeElem = /** @class */ (function () {
    function SwipeElem(elem, option) {
        this.default = {
            width: 1000,
            panelText: '左右にスクロールしてご覧ください'
        };
        this.$elem = $(elem);
        this.setting = __assign(__assign({}, this.default), option);
        this.build();
    }
    SwipeElem.prototype.build = function () {
        var _this = this;
        this.$elem.each(function (i, elem) {
            var classes = $(elem).attr('class').split(' ').filter(function (val) {
                return val !== 'sw-Swipe' && val.search(/sw\-/) == 0;
            });
            var $caption = undefined;
            if ($(elem).children('caption').length) {
                var contents = $(elem).children('caption').html();
                var captionClasses = $(elem).children('caption').attr('class');
                $caption = $('<div class="sw-Swipe_Caption" />');
                $caption.addClass(captionClasses).html(contents);
                $(elem).children('caption').hide(0);
            }
            ;
            $(elem).css('width', _this.setting.width).wrap('<div class="sw-SwipeContainer" /><div class="sw-SwipeContainer_Inner" />');
            var $wrapper = $(elem).closest('.sw-SwipeContainer');
            var $inner = $(elem).closest('.sw-SwipeContainer_Inner');
            $wrapper.addClass(classes.join(' '));
            $inner.append(_this.getPanel()).on('scroll.swipe-elem', function (e) {
                $(e.currentTarget).children('.sw-Swipe_Panel').addClass('-hidden');
                $(e.currentTarget).off('.swipe-elem');
            });
            if ($caption) {
                $wrapper.prepend($caption);
            }
            ;
        });
    };
    SwipeElem.prototype.getPanel = function () {
        return '<div class="sw-Swipe_Panel">' + this.setting.panelText + '</div>';
    };
    return SwipeElem;
}());
$.fn.swipeElem = function (option) {
    var opt = option ? option : {};
    return this.each(function (i, elem) {
        new SwipeElem(elem, opt);
    });
};
