"use strict";
/*!
 *
 *  scroll-anim.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        this.max = 0;
        this.targets = this.getTargets();
        this.max = this.targets.length;
        if (this.max !== 0) {
            this.event();
        }
        ;
    }
    default_1.prototype.event = function () {
        var _this = this;
        $(window).on('scroll.scrollAnim', function (e) {
            var _top = $(e.currentTarget).scrollTop();
            _this.targets.forEach(function (val) {
                if (!$(val.elem).is('.-run') && (_top + (util.wh / 2) + (util.wh / 3)) > val.top) {
                    $(val.elem).addClass('-run');
                    _this.max--;
                }
                ;
                if (_this.max === 0) {
                    $(window).off('.scrollAnim');
                }
                ;
            });
        });
        $(window).on('resize.scrollAnim', function () {
            _this.targets = _this.getTargets();
        });
        $(window).on('load.scrollAnim', function () {
            _this.targets = _this.getTargets();
        });
    };
    default_1.prototype.getTargets = function () {
        var arr = [];
        $('.sw-SA').each(function (i, elem) {
            arr.push({
                top: $(elem).offset().top,
                elem: elem
            });
        });
        return arr;
    };
    return default_1;
}());
exports.default = default_1;
