"use strict";
/*!
 *
 *  fit-height.ts
 *
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Accordion = /** @class */ (function () {
    function Accordion(elem, option) {
        var _this = this;
        this.default = {
            headClass: '.c-Acod_Head_Btn',
            bodyClass: '.c-Acod_Body'
        };
        this.$elem = $(elem);
        this.setting = __assign(__assign({}, this.default), option);
        this.$head = this.$elem.find(this.setting.headClass);
        this.$head.on('click', function (e) {
            e.preventDefault();
            if ($(e.currentTarget).attr('aria-expanded') === 'false') {
                _this.open(e.currentTarget);
            }
            else {
                _this.close(e.currentTarget);
            }
            ;
        });
    }
    Accordion.prototype.open = function (head) {
        var target = '#' + $(head).attr('aria-controls');
        var h = $(target).children().get(0).offsetHeight;
        $(head).attr('aria-expanded', 'true');
        $(target).attr('aria-hidden', 'false').css('height', h);
    };
    Accordion.prototype.close = function (head) {
        var target = '#' + $(head).attr('aria-controls');
        $(head).attr('aria-expanded', 'false');
        $(target).attr('aria-hidden', 'true').css('height', 0);
    };
    return Accordion;
}());
$.fn.accordion = function (option) {
    var opt = option ? option : {};
    return this.each(function (i, elem) {
        new Accordion(elem, opt);
    });
};
