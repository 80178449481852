"use strict";
/*!
 *
 *  check-webp.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkWebP = void 0;
function checkWebP(callback) {
    var webP = new Image();
    webP.onload = webP.onerror = function () {
        callback(webP.height == 2);
    };
    webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
}
exports.checkWebP = checkWebP;
;
